import { Injectable } from '@angular/core';
import {
  DATE_DAY_NUMBER_MONTH_WORD_TIME,
  DATE_FULL,
  DATE_WITH_TIME_NO_MS_BACKEND,
} from '@shared/constants/common.const';
import { DatePeriodKey } from '@shared/enums/keys.enum';
import { IDateWithData } from '@shared/models/date.model';
import * as moment from 'moment';
import { PluralPipe } from '@shared/pipes/plural.pipe';
import {
  AGE_PLURAL,
  DAYS_PLURAL,
  HOURS_PLURAL,
  MINUTES_PLURAL,
  MONTHS_PLURAL,
  SECONDS_PLURAL,
} from '@shared/constants/plural.const';

interface ICompareTimeItem {
  lessThen: number;
  calcDeltaRatio: number;
  fullPluralList: string[];
  shortItem: string;
}

const COMPARE_TIME_SET: ICompareTimeItem[] = [
  {
    lessThen: 60,
    calcDeltaRatio: 1,
    fullPluralList: SECONDS_PLURAL,
    shortItem: 'сек',
  },
  {
    lessThen: 3600,
    calcDeltaRatio: 60,
    fullPluralList: MINUTES_PLURAL,
    shortItem: 'мин',
  },
  {
    lessThen: 24 * 3600,
    calcDeltaRatio: 3600,
    fullPluralList: HOURS_PLURAL,
    shortItem: 'ч',
  },
  {
    lessThen: 24 * 3600 * 30,
    calcDeltaRatio: 24 * 3600,
    fullPluralList: DAYS_PLURAL,
    shortItem: 'дн',
  },
];

@Injectable({
  providedIn: 'root',
})
export class DateService {
  readonly moment = moment;
  readonly minDateGlobal = this.setMinTimeToMoment(moment('01.01.1900', DATE_FULL));
  readonly maxDateGlobal = this.setMaxTimeToMoment(moment('31.12.2036', DATE_FULL));
  readonly currentDate = this.setMinTimeToMoment(moment());

  readonly today = moment().startOf('day');
  readonly yesterday = this.today.clone().subtract(1, 'day');
  readonly dayBeforeYesterday = this.yesterday.clone().subtract(1, 'day');

  constructor(private readonly plural: PluralPipe) {}

  setLocale(locale: string) {
    moment.locale(locale);
  }

  isValid(date: string, format?: string, strict?: boolean) {
    return moment(date, format, strict).isValid();
  }

  transfromStringDateToFormat(stringDate: string, currentFormat: string, targetFormat: string): string {
    if (stringDate && currentFormat && targetFormat && moment(stringDate, currentFormat, true).isValid()) {
      return moment(stringDate, currentFormat).format(targetFormat);
    }
  }

  transfromStringToMoment(
    stringDate: string,
    dateFormat = DATE_WITH_TIME_NO_MS_BACKEND,
    strict?: boolean,
    addUtcOffset = true,
  ): moment.Moment {
    if (stringDate && dateFormat) {
      const transformedDate = moment(stringDate, dateFormat, strict);

      if (addUtcOffset) {
        return transformedDate.add(transformedDate.utcOffset(), 'minutes');
      }
      return transformedDate;
    }
  }

  transfromMomentToString(date: moment.Moment, dateFormat = DATE_WITH_TIME_NO_MS_BACKEND): string {
    if (date && dateFormat) {
      // return date.format(dateFormat);

      return moment(date).format(dateFormat);
    }
  }

  transfromMomentToSmartString(date: moment.Moment, dateFormat = DATE_DAY_NUMBER_MONTH_WORD_TIME): string {
    if (!date) {
      return;
    }

    if (this.today.isBefore(date)) {
      return moment(date).fromNow().toLowerCase();
    }
    /*Добавляет время в конце чата */

    // if (this.dayBeforeYesterday.isBefore(date)) {
    //   return date.calendar().toLowerCase();
    // }

    return moment(date).format(dateFormat);
  }

  // Определение статус онлайн участника и когда он был в сети
  public transformMomentToOnlineStatusString(
    rawDate: moment.Moment,
    dateFormat: string = DATE_DAY_NUMBER_MONTH_WORD_TIME,
  ): string {
    if (!rawDate) {
      return;
    }
    const date: moment.Moment = moment(rawDate);

    const yesterday: string = date.add(-1, 'days').utc().format('YYYY-MM-DD');

    if (this.today.isBefore(rawDate)) {
      return `${this.transformMomentToRoundOnlineStatusString(rawDate, true).toLowerCase()} назад`;
    }

    const yearLabel: string = `${moment().diff(date, 'years') >= 1 ? date.year() : ''}`;

    return this.today.diff(yesterday, 'days') === 2
      ? `Вчера в ${date.format('HH:mm')}`
      : `${date.format(dateFormat)} ${yearLabel} в ${date.format('HH:mm')}`;
  }

  public transformMomentToRoundOnlineStatusString(date: moment.Moment, fullDelta: boolean = false): string {
    if (!date) {
      return;
    }

    const deltaInSec: number = moment().diff(date, 'seconds');

    for (let timeMisc of COMPARE_TIME_SET) {
      if (deltaInSec < timeMisc.lessThen) {
        const delta: number = Math.floor(deltaInSec / timeMisc.calcDeltaRatio);

        return `${delta} ${
          fullDelta ? `${this.plural.transform(delta, timeMisc.fullPluralList)}` : timeMisc.shortItem
        }`;
      }
    }

    const deltaInMonth: number = moment().diff(date, 'month');

    const yearDelta: number = Math.floor(deltaInMonth / 12);

    return yearDelta < 1
      ? `${deltaInMonth} ${fullDelta ? `${this.plural.transform(deltaInMonth, MONTHS_PLURAL)}` : 'мес'}`
      : `${yearDelta} ${this.plural.transform(yearDelta, AGE_PLURAL)}`;
  }

  setMinTimeToMoment(date: moment.Moment): moment.Moment {
    if (date) {
      return date.startOf('day');
    }
  }

  setMaxTimeToMoment(date: moment.Moment): moment.Moment {
    if (date) {
      return date.endOf('day');
    }
  }

  isCurrentMonth(date: moment.Moment): boolean {
    return moment().startOf('month').isSame(date.startOf('month'));
  }

  getYearMonths<T>(startFrom?: moment.Moment): IDateWithData<T>[] {
    if (!startFrom) {
      startFrom = this.setMinTimeToMoment(moment().startOf('year'));
    }

    const yearMonths = moment.months().map((_, index) => {
      const startMonth = startFrom.clone().set('month', startFrom.month() + index);
      return {
        dateStart: startFrom.clone().set('month', startMonth.month()),
        dateEnd: startFrom.clone().set('month', startMonth.month() + 1),
        monthStart: startMonth.clone().startOf('month'),
        monthEnd: startMonth.clone().endOf('month'),
        isCurrentMonth: this.isCurrentMonth(startMonth),
      } as IDateWithData<T>;
    });
    return yearMonths;
  }

  getDateRangeByDatePeriodKey(period: DatePeriodKey): [moment.Moment, moment.Moment] {
    switch (period) {
      case DatePeriodKey.Today: {
        return [this.setMinTimeToMoment(moment()), this.setMaxTimeToMoment(moment())];
      }
      case DatePeriodKey.LastWeek: {
        return [this.setMinTimeToMoment(moment().subtract(7, 'days')), this.setMaxTimeToMoment(moment())];
      }
      case DatePeriodKey.LastMonth: {
        return [this.setMinTimeToMoment(moment().subtract(1, 'month')), this.setMaxTimeToMoment(moment())];
      }
      case DatePeriodKey.LastTwoMonths: {
        return [this.setMinTimeToMoment(moment().subtract(2, 'months')), this.setMaxTimeToMoment(moment())];
      }
      case DatePeriodKey.LastThreeMonths: {
        return [this.setMinTimeToMoment(moment().subtract(3, 'months')), this.setMaxTimeToMoment(moment())];
      }
      default: {
        return [null, null];
      }
    }
  }

  getDatePeriodKeyByDateRange(firstDate: moment.Moment, secondDate: moment.Moment): DatePeriodKey {
    const diffInMonths = Math.round(Math.abs(firstDate.diff(secondDate, 'months')));
    const diffInWeeks = Math.round(Math.abs(firstDate.diff(secondDate, 'weeks')));
    const diffInDays = Math.round(Math.abs(firstDate.diff(secondDate, 'days')));

    if (diffInDays === 1) {
      return DatePeriodKey.Today;
    }
    if (diffInWeeks === 1) {
      return DatePeriodKey.LastWeek;
    }
    if (diffInMonths === 1) {
      return DatePeriodKey.LastMonth;
    }
    if (diffInMonths === 3) {
      return DatePeriodKey.LastThreeMonths;
    }
  }
}
